import React from 'react'
import SlickSlider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
}

const slides = [
  {
    title: 'Österreichs größtes Schnitzel',
    text: 'Entdecken Sie die berühmte Spezialität des Hauses',
    img: '/uploads/stadlwirt-riesen-wiener-schnitzel.jpg',
  },
  {
    title: 'Im Herz der Alpen',
    text: 'Wunderschöne Natur im Dreiländereck',
    img: '/uploads/nauders.jpg',
  },
  {
    title: 'Urige Stimmung',
    text: 'Wir legen Wert auf Tradition & Gaumen',
    img: '/uploads/haus-stadlwirt.jpg',
  },
]

const Slide = ({ title, text, img }: SlideProps) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        height: '80vh',
      }}
      className="relative bg-cover bg-center bg-no-repeat overflow-hidden"
    >
      <div
        style={{
          background: 'linear-gradient(180deg,hsla(0,0%,100%,0) 0,#000)',
          minHeight: '50%',
        }}
        className="absolute bottom-0 left-0 w-full text-white py-10 pt-36"
      >
        <div
          style={{
            bottom: '2rem',
          }}
          className="container absolute"
        >
          <h2 className="text-2xl">{title}</h2>
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}

interface SlideProps {
  title: string
  text: string
  img: string
}

export const HomePageSlider = () => {
  return (
    <SlickSlider {...settings}>
      {slides.map(slide => {
        const { title, text, img } = slide

        return <Slide key={title} title={title} text={text} img={img} />
      })}
    </SlickSlider>
  )
}

const apartmentSliderSettings = {
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

export const ApartmentSlider = ({ children }: ApartmentSliderProps) => {
  return <SlickSlider {...apartmentSliderSettings}>{children}</SlickSlider>
}

interface ApartmentSliderProps {
  children: React.ReactNode
}
